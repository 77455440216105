<template>
  <div class="about-us" oncontextmenu="return false" onselectstart="return false">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" id="pdfDom">
      <div class="table" :class="{'pdfheight': isPrint}">
        <h1 class="title">
          <b>
            【学前儿童认知与理解】
            <br/><br/>个人报告
          </b>
        </h1>
        <div class="author">
          <p>
            <i>

              <br/>
            </i>
          </p>
          <p>杨敦雄 编制</p>
        </div>
        <p class="title3 tx-l">测验介绍</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th>
              <p class="tips tx-idt2">
                 本测验主要作为评估零岁至七岁儿童认知与理解的临床测评工具。目的是通过测验，确定儿童发展水平，以便进行早期干预和治疗，并根据测验结果分析，进一步了解儿童的干预计划。个体认知发展经过感知运动阶段、前运动阶段、具体运动阶段和形式运算阶段。每一阶段都有其特点和发展的核心。</p>
                 </th>
          </tr>
        </table>
        <div class="head-title">
          <p class="title3 tx-l">基本资料</p>
          <p class="title3 tx-l">个案编号：{{baseInfo.sn}}</p>
        </div>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th width="140px">儿童姓名</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.child_name"/>
              </div>
            </td>
            <th>幼儿性别</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.child_sex"/>
              </div>
            </td>
          </tr>
          <tr>
          
            <th>出生日期</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.birthday"/>
              </div>
            </td>
            <th width="140px">评估日期</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.create_time"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>换算年龄</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.age"/>
              </div>
            </td>
            <th>主要照顾者</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.care_man"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>受访者姓名</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.parent"/>
              </div>
            </td>
            <th>与儿童关系</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.relation"/>
              </div>
            </td>
          
          </tr>
        
         
          <tr>
          
            <th>受访者电话</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.mobile"/>
              </div>
            </td>
            <th>居住地区</th>
            <td >
              <div class="input">
                <input type="text" readonly v-model="baseInfo.detail.address"/>
              </div>
            </td>
          
          </tr>
      
        </table>
        <p class="title3 tx-l">向度说明</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
           <th width="140px">基础物理概念认知</th>
            
           <td class="tx-c" >评估不同阶段儿童常见物品、身体部位认知、形状概念、颜色概念、尺寸概念、重量与容量概念、质地概念、空间位置概念、时间概念等认知。</td>
           
         </tr>
         <tr>
           <th>基础数学概念认知</th>
            
           <td class="tx-c">评估不同阶段儿童数字、数量、点数、数序、基础运算、钱币概念等数学认知。</td>
           
         </tr>
         <tr>
           <th>基础逻辑概念</th>
           
               <td class="tx-c">评估不同阶段儿童在类比概念、因果关系等逻辑概念的认知。</td>
           
         </tr>
        
         <tr>
          <th>生活自理</th>
           
          <td class="tx-c">评估不同阶段儿童日常生活中使用如厕、生活物品的认知。</td>
          
        </tr>
       </table>
        
      </div>
      <div class="table" :class="{'pdfheight': isPrint}">
        <!-- 分数结果 -->
        <p class="title3 tx-l">发展规划</p>
        <table border="1" cellspacing="1" cellpadding="1">
     
          <tr>
            <th th width="50px">发展水平</th>
            <td colspan="14" class="tx-c">{{scoreRe.conclusion}}</td>
          </tr>
          <tr>
            <th>成长规划</th>
            <td colspan="14" class="tx-c">
              <p class="tips" v-html="scoreRe.desc"></p>
            </td>
          </tr>
        </table>
    
      </div>

    </div>
<!-- 
    <button class="print" v-on:click="printPdf">打印</button> -->
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import HeaderNav from "@/components/HeaderNav";
  import FooterNav from "@/components/FooterNav";
  import echarts from "echarts";

  export default {
    name: "aboutus",
    data() {
      return {
        current: "",
        isPrint: false,
        baseInfo: {},
        cateScore: [],
        holding: "",
        result: "",
        scoreList: [],
        scoreRe: {},
        word: {},
        know: [],
        speak: [],
        vocabulary: [
          "一般名词(68)",
          "衣物名称(9)",
          "方位词(9)",
          "代词(8)",
          "身体部位名称(14)",
          "食物名称(30)",
          "动物名称(21)",
          "形容词(24)",
          "动作词(73)",
          "称谓／名字(19)",
          "社交语(8)",
          "其他词汇(28)"
        ],
        knowWords: [],
        speakWords: []
      };
    },
    components: {
      HeaderNav,
      FooterNav,
     
    },
    methods: {
      getData() {
        let params = {
          exam_id: this.$route.query.eid
        };
        this.$post("report/getReport", params)
          .then(res => {
            console.log(res);
            if (res.code === 1) {
              this.baseInfo = res.data.base_info;
              this.detail = res.data.base_info.detail;
              this.cateScore = res.data.cate_score;
              this.holding = res.data.holding;
              this.result = res.data.result;
              this.scoreList = res.data.score.level_score;
              this.scoreRe = res.data.cate_score[0];
              //插入换行符
              this.scoreRe.desc = this.scoreRe.desc.replace(/\n/g, "<br/>");
              this.word = res.data.word;
              this.knowWords = res.data.word.total_score.know;
              this.speakWords = res.data.word.total_score.speak;
              for (let i in this.word.type_score) {
                this.know.push(this.word.type_score[i].know_score);
                this.speak.push(this.word.type_score[i].speak_score);
              }
              this.$nextTick(() => {
                this.drawLine();
                this.drawLine2();
              });
              this.$nextTick(() => {
                if (this.$route.query.print) {
                  this.printPdf();
                }
              });
            } else {
              this.$layer.msg(res.msg);
            }
          })
          .catch(response => {
            this.$layer.msg("LOADING...");
          });
      },
      printPdf() {
        this.isPrint = true;
        setTimeout(() => {
          this.getPdf("学前儿童认知与理解", () => {
            this.isPrint = false;
          });
        }, 1500)
      },
      drawLine() {
        let data = [];
        for (let i = 0; i < this.scoreList.length; i++) {
          data.push(this.scoreList[i].level_score);
        }
        // console.log("data", data);
        // // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById("charts1"));
        // 绘制图表
        myChart.setOption({
          title: {
            text: "",
            textAlign: "left",
            x: "center",
            y: "top"
          },
          tooltip: {},
          xAxis: [
            {
              name: "发展水平",
              data: [
                "等级1",
                "等级2",
                "等级3",
                "等级4",
                "等级5",
                "等级6",
                "等级7",
                "等级8",
                "等级9",
                "等级10",
                "等级11",
                "等级12",
                "等级13",
                "等级14"
              ]
            }
          ],
          yAxis: {
            type: "value",
            scale: true,
            name: "题数",
            max: 6,
            min: 0
          },
          series: [
            {
              type: "bar",
              data: data,
              itemStyle: {
                normal: {
                  label: {
                    show: true, //开启显示
                    position: "top", //在上方显示
                    textStyle: {
                      //数值样式
                      color: "black",
                      fontSize: 16
                    }
                  }
                }
              }
            },
            {
              data: [5, 5, 5, 5, 6, 6, 6, 6, 6],
              type: "line",
              step: "middle",
              symbol: "none"
            }
          ]
        });
      },
      drawLine2() {
        let know = [];
        let speak = [];
        for (let i in this.word.type_score) {
          know.push(this.word.type_score[i].know_score);
          speak.push(this.word.type_score[i].speak_score);
        }
        // 基于准备好的dom，初始化echarts实例
        let myChart = echarts.init(document.getElementById("charts2"));
        // 绘制图表
        let seriesLabel = {
          normal: {
            show: true,
            color: "black",
            position: "right" //在上方显示
          }
        };
        myChart.setOption({
          title: {
            text: "零岁至三岁婴幼儿词汇量总计",
            textAlign: "left",
            x: "center",
            y: "top"
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            }
          },
          legend: {
            data: ["听懂", "说出"],
            orient: "horizontal",
            x: "right",
            y: "top"
          },
          grid: {
            left: "3%",
            right: "8%",
            bottom: "6%",
            containLabel: true
          },
          xAxis: {
            name: "数量",
            type: "value",
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            name: "词汇种类",
            type: "category",
            data: [
              "一般名词(68)",
              "衣物名称(9)",
              "方位词(9)",
              "代词(8)",
              "身体部位名称(14)",
              "食物名称(30)",
              "动物名称(21)",
              "形容词(24)",
              "动作词(73)",
              "称谓／名字(19)",
              "社交语(8)",
              "其他词汇(28)"
            ]
          },
          series: [
            {
              name: "听懂",
              type: "bar",
              label: seriesLabel,
              data: know
            },
            {
              name: "说出",
              type: "bar",
              label: seriesLabel,
              data: speak
            }
          ]
        });
      }
    },
    created() {
      this.getData();
    }
  };
</script>

<style lang="less" scoped>
  @import url("../../assets/css/page/result");
</style>
